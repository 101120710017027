.the__vision {
    color: #fff;
    background-image: url("../../assets//TheVision/MainBody.png");
    overflow-x: hidden;
    height: 100vh;
    // padding-bottom: 5vh;

    .top__image {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .content__container {
        display: flex;
        flex-direction: row;
        // margin-bottom: 5vh;

        .left {
            width: 45%;
            padding: 0px 0px 100px 0px;

            img {
                width: 110%;
                transform: translateX(-15%);
            }

            @media (max-width: 750px) {
                padding: 0px;
                width: 100%;

                img {
                    width: 90%;
                    transform: translateX(-15%);
                }
            }
        }

        .right {
            padding: 50px 0px;
            width: 45%;
            display: flex;
            flex-direction: column;
            // justify-content: center;
            overflow: visible;
            .vision__header {
                font-size: 110px;
                width: max-content;
                font-family: Aminute;

                @media (max-width: 1500px) {
                    font-size: 90px;
                }
                @media (max-width: 1100px) {
                    font-size: 80px;
                    justify-content: start;
                }
                @media (max-width: 750px) {
                    font-size: 25px;
                    justify-content: start;
                }
            }

            .vision__content {
                font-size: 22px;
                font-family: RedHat;
                width: 100%;

                @media (max-width: 1250px) {
                    font-size: 18px;
                }
                @media (max-width: 1050px) {
                    font-size: 16px;
                }
                @media (max-width: 750px) {
                    font-size: 14px;
                    width: 100%;
                    margin-bottom: 35px;
                    margin-left: auto;
                    margin-right: auto;
                    // margin: 0px auto;
                    // margin-right: 25%;
                }
            }

            // @keyframes move-text {
            //     from {
            //         transform: translateX(-100px);
            //     }
            //     to {
            //         transform: translateX(0px);
            //     }
            // }
            @media (max-width: 750px) {
                width: 80%;
                padding: 0px;
                margin: auto;
            }
        }
        @media (max-width: 750px) {
            flex-direction: column;
            margin-top: 5%;
        }
    }

    @media (max-width: 1900px) {
        height: max(85vh, 1000px);
    }

    @media (max-width: 1250px) {
        height: max(75vh, 850px);
    }

    @media (max-width: 750px) {
        height: max(75vh, 650px);
    }
}

.big__greams__container {
    color: #fff;
    background-image: url("../../assets//TheVision/MainBody.png");
    overflow-x: hidden;
    height: calc(100vh + 150px);
    display: flex;
    flex-direction: column;
    padding-top: 150px;
    // background-color: #a6aab2;
    // height: 100vh;
    .big__dreams {
        display: flex;
        flex-direction: row;
        overflow-x: visible;
        .left {
            width: 50% !important;
            padding: 0px 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-shrink: 0;

            .big__dreams__header {
                display: flex;
                flex-direction: row;
                width: 90% !important;
                // justify-content: center;
                font-size: 110px;
                // max-width: max-content;
                font-family: Aminute;
                text-align: start;
                margin: 0px auto;

                @media (max-width: 1500px) {
                    font-size: 90px;
                }
                @media (max-width: 1100px) {
                    font-size: 80px;
                    justify-content: start;
                }
                @media (max-width: 750px) {
                    font-size: 25px;
                    justify-content: start;
                }
            }

            .big__dreams__content {
                font-size: 22px;
                width: 90%;
                color: #a6aab2;
                font-family: RedHat;
                text-align: start;
                margin: 0px auto;

                @media (max-width: 1250px) {
                    font-size: 18px;
                }
                @media (max-width: 1050px) {
                    font-size: 16px;
                }
                @media (max-width: 750px) {
                    font-size: 14px;
                    // margin: 0px auto;
                    // margin-right: 25%;
                }
            }

            // @keyframes move-bd-text {
            //     from {
            //         transform: translateX(-200px);
            //     }
            //     to {
            //         transform: translateX(400px);
            //     }
            // }

            @media (max-width: 750px) {
                flex-direction: column;
                justify-content: space-around;
                width: 90% !important;
            }
        }

        .right {
            width: 50%;
            height: 50vh;
            // height: max-content;
            margin: auto 0px auto 0;
            @media (max-width: 750px) {
                // width: 100%;
                margin: auto 0px auto 4%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
        .grid__cont {
            width: max-content;
            margin-right: auto;
        }

        .animated__persons__container {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
            height: max-content;
            margin: auto;

            img {
                // min-height: fit-content;
                // margin: 0px auto;
                margin-top: auto !important;
                margin-right: auto;
                object-fit: contain;
                // height: fit-content !important;

                @media (max-width: 1250px) {
                    max-width: 120px;
                    // width: 100px;
                }

                @media (max-width: 1050px) {
                    max-width: 100px;
                    // width: 100px;
                }
                @media (max-width: 750px) {
                    max-width: 60px;
                    // width: 60px;
                }
            }

            img:nth-child(2) {
                z-index: 3;
            }

            img:nth-child(3) {
                z-index: 6;
            }

            img:nth-child(4) {
                z-index: 5;
            }

            img:nth-child(5) {
                z-index: 4;
            }

            img:nth-child(6) {
                z-index: 3;
            }

            img:nth-child(7) {
                z-index: 2;
            }

            img:nth-child(8) {
                z-index: 1;
            }

            @media (max-width: 750px) {
                margin: auto;
                margin-left: 15%;
            }
        }

        @media (max-width: 750px) {
            flex-direction: column;
            width: 100%;
        }
    }

    .top__image {
        width: 100%;

        img {
            width: 100%;
        }
    }
    .bottom__image {
        width: 100%;
        margin-top: auto;
        padding: 0px !important;
        display: flex;
        flex-direction: column;

        img {
            width: 100%;
            margin: 0px !important;
            margin-top: auto !important;
        }
    }
}

.sliding-text {
    opacity: 0;
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    transform: translate3d(-100%, 0, 0); /* Start off-screen to the left */
}

.sliding-text.visible {
    opacity: 1;
    transform: translate3d(0, 0, 0); /* Slide into place */
}
