.brands__container {
    height: max(94vh, 850px);
    width: 100vw;
    display: flex;
    flex-direction: row;

    .col {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        height: 100%;
        position: relative;

        .content__container {
            z-index: 99999;
            // width: max-content;
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 75%;
            margin: 0px auto;
            // background-color: #327eef;

            .naar__image {
                width: 203px;
                // height: 287px;
                margin: auto;
                position: relative;
                z-index: 3;
                aspect-ratio: 10 / 12;

                @media (max-width: 1250px) {
                    width: 170px;
                }

                @media (max-width: 1050px) {
                    width: 130px;
                }

                @media (max-width: 750px) {
                    width: 100px !important;
                }
            }

            .hover__content {
                max-height: 0px;
                transition: margin 0.1s ease-in-out, max-height 0.4s linear;
                overflow-y: hidden;
                color: #fff;
                text-align: center;

                .explore {
                    font-size: 21px;
                    letter-spacing: 3px;
                    font-family: RedHat;
                    font-weight: 700;
                    margin-top: 90px;

                    @media (max-width: 1250px) {
                        margin-top: 70px;
                        font-size: 19px;
                    }

                    @media (max-width: 1050px) {
                        margin-top: 50px;
                        font-size: 17px;
                    }

                    @media (max-width: 750px) {
                        margin-top: 30px;
                        font-size: 15px;
                    }
                }

                .socials {
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    margin-top: 45px;
                    overflow: hidden;
                    justify-content: center;

                    div {
                        height: 58px;
                        width: 58px;
                        border-radius: 29px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        a {
                            img {
                                margin: auto;
                                width: 28px;
                                margin-top: 2px;

                                @media (max-width: 750px) {
                                    width: 18px;
                                }
                            }
                        }

                        @media (max-width: 750px) {
                            width: 36px;
                            height: 36px;
                            border-radius: 18px;
                        }
                    }

                    .bluebg {
                        background-color: #6ba6ff69;
                    }

                    .fuschiabg {
                        background-color: #ff64af8c;
                    }

                    @media (max-width: 1250px) {
                        margin-top: 30px;
                    }

                    @media (max-width: 1050px) {
                        margin-top: 25px;
                    }

                    @media (max-width: 750px) {
                        margin-top: 20px;
                    }
                }

                .description {
                    margin-top: 50px;
                    font-family: Aminute !important;
                    font-size: 19px;
                    line-height: 24px;

                    @media (max-width: 1050px) {
                        font-size: 16px;
                        line-height: 18px;
                    }

                    @media (max-width: 750px) {
                        font-size: 14px;
                        line-height: 16px;
                    }
                }

                .visit__site {
                    margin-top: 40px;
                    a {
                        font-size: 21px;
                        letter-spacing: 3px;
                        font-family: RedHat;
                        font-weight: 700;
                        text-decoration: none;
                        color: #fff;

                        @media (max-width: 1050px) {
                            font-size: 17px;
                            line-height: 18px;
                        }

                        @media (max-width: 750px) {
                            font-size: 14px;
                            line-height: 15px;
                        }
                    }

                    @media (max-width: 1050px) {
                        margin-top: 25px;
                    }

                    @media (max-width: 750px) {
                        margin-top: 20px;
                    }
                }
            }

            &:hover {
                .hover__content {
                    max-height: 100%;
                }
            }
        }

        .imgs__container {
            // width: 203px;
            height: 243px;
            // height: 120px;
            margin: auto !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            z-index: 3;
            // aspect-ratio: 10 / 12;

            @media (max-width: 1250px) {
                height: 204px;
            }

            @media (max-width: 1050px) {
                height: 156px;
            }

            @media (max-width: 750px) {
                height: 120px;
            }

            img {
                margin: 0px auto;
                @media (max-width: 1250px) {
                    width: 170px;
                }

                @media (max-width: 1050px) {
                    width: 130px;
                }

                @media (max-width: 750px) {
                    width: 120px;
                }
            }
        }
    }

    .col:nth-child(1)::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("../../assets/Brands/brand1.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        // opacity: 0.7; /* Adjust the opacity here */
        z-index: 1; /* Behind the content */
    }
    .col:nth-child(1)::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #327eef;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.4; /* Adjust the opacity here */
        z-index: 1; /* Behind the content */
    }

    .col:nth-child(2)::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("../../assets/Brands/brand2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.8; /* Adjust the opacity here */
        z-index: 1; /* Behind the content */
    }
    .col:nth-child(2)::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #e71d7d99;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.4; /* Adjust the opacity here */
        z-index: 1; /* Behind the content */
    }

    @media (max-width: 750px) {
        height: 80vh;
    }
}
