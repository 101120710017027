.closeBtn {
    position: absolute;
    right: 50px;
    top: 50px;
    font-size: 40px;
    background-color: transparent;
    color: #fff;
    border: none;
    cursor: pointer;
    z-index: 99;
    @media (max-width: 750px) {
        font-size: 25px;
    }
}

.formContainer {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 100%;
    color: #fff;
    overflow-y: auto;
    margin-left: 5%;
    margin-top: 0%;

    overflow: auto; /* Enable scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    .left {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        font-size: 80px;
        margin: 3vh auto 1vh auto;
        text-align: center;
        font-family: Aminute;
        // background-color: rgb(152, 152, 152);
        text-transform: capitalize;
        padding: 15px;
        @media (max-width: 1150px) {
            width: 100%;
            font-size: 65px;
        }
        @media (max-width: 750px) {
            width: 100%;
            width: fit-content;
            font-size: 35px;
        }
    }

    .right {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: start !important;
        font-family: Aminute;

        overflow: auto; /* Enable scrolling */
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        @media (max-width: 900px) {
            width: 100%;
            overflow-y: auto;
        }
    }

    .right::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 900px) {
        flex-direction: column;
    }
}

.formContainer::-webkit-scrollbar {
    display: none;
}

.form {
    max-height: 90%;
    width: 80%;
    margin: auto auto;
    display: flex;
    flex-direction: column;
    color: #fff;
    font-family: Aminute !important;
    gap: 15px;
    .row {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 4%;
        font-size: 18px;

        .filed__container {
            display: flex;
            flex-direction: column;
            width: 48%;
            font-family: Aminute;
            font-size: 22px;
            gap: 7px;

            .error__label {
                color: rgb(175, 0, 0);
            }
        }
        &.error__label {
            color: rgb(175, 0, 0);
        }

        .end__justified {
            justify-content: end;
        }

        .filed__container__full {
            display: flex;
            flex-direction: column;
            width: 100%;
            font-family: Aminute;
            font-size: 18px;
            gap: 10px;

            .error__label {
                color: rgb(175, 0, 0);
            }
        }

        label {
            font-family: Aminute;
            font-size: 15px;
            @media (max-width: 750px) {
                font-size: 13px;
            }
        }

        input {
            box-sizing: border-box;
            height: 32px;
            width: 100%;
            border-radius: 24px !important;
            border: none;
            background-color: rgb(67, 67, 67);
            color: rgb(153, 153, 153);
            font-size: 15px;
            font-family: Aminute;
            padding: 0px 25px;

            ::placeholder {
                color: rgb(130, 130, 130);
            }
            @media (max-width: 750px) {
                font-size: 13px;
            }
        }

        textarea {
            box-sizing: border-box;
            min-height: 120px;
            min-width: 100% !important;
            border-radius: 24px !important;
            border: none;
            background-color: rgb(67, 67, 67);
            color: rgb(153, 153, 153);
            font-size: 18px;
            font-family: Aminute;
            padding: 10px 25px;

            ::placeholder {
                color: rgb(130, 130, 130);
            }
            @media (max-width: 750px) {
                font-size: 13px;
                min-height: 80px;
            }
        }

        .my__select {
            box-sizing: border-box;
            height: 40px;
            width: 100%;
            border-radius: 24px;
            border: none;
            background-color: rgb(67, 67, 67);
            color: rgb(153, 153, 153);
            font-size: 16px;
            font-family: Aminute;
            padding: 0px 25px;
        }
        button {
            background-color: #fff;
            height: 48px;
            border-radius: 24px;
            width: 75%;
            border: none;
            color: #000;
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
            @media (max-width: 750px) {
                width: 100%;
            }
        }
    }

    .full {
        width: 100% !important;
        // background-color: #fff;
    }
    @media (max-width: 750px) {
        width: 100% !important;
        // height: 100% !important;
        max-height: 100% !important;
        gap: 10px !important;
    }
}
