.screen__sized__section {
    width: 100vw;
    height: 100vh;

    .video__intro {
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;

        video {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            transform: translate(-50%, -50%);
            object-fit: cover; /* Ensures the video covers the container */
            z-index: -1;
        }
    }
}
