.app {
  min-height: 100vh;
  width: 100vw !important;
  // background-color: rgb(22, 22, 22);
  // background-color: #000;
  display: flex;
  flex-direction: column;
  position: relative;
  overscroll-behavior: none;
}

.app__header {
  height: 70px;

  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -ms-filter: blur(1px);
  -o-filter: blur(1px);
  filter: blur(1px);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  opacity: 0.5;
  // overflow-y: visible;
}

.blurry-background {
  position: fixed;
  width: 100vw;
  left: 0px;
  right: 0px;
  top: 0px !important;
  background-color: #000;
  // background-color: rgba(255, 255, 255, 0.122); /* Semi-transparent white background */
  // backdrop-filter: blur(5px); /* Apply blur effect */
  // -webkit-backdrop-filter: blur(5px); /* For Safari support */
  padding: 15px 0px;
  // margin: 50px auto;
  text-align: center;
  z-index: 9;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: #fff;
  text-transform: uppercase;
  overflow: visible;
  overflow-x: hidden;
  height: 55px;
  z-index: 99999999999;

  .dropdown__section {
    display: none;
    position: fixed;
    flex-direction: column;
    // justify-content: space-around;
    width: max-content;
    text-align: center;
    align-items: center;
    z-index: 99;
    background-color: rgb(0, 0, 0);
    top: 60px;
    right: 0px;
    padding: 10px 40px;
    color: #fff;

    div {
      cursor: pointer;
      height: 35px;
    }

    @media (max-width: 750px) {
      display: flex;
    }
  }

  .middle__section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: calc(70% - 150px);
    text-align: center;
    align-items: center;
    font-family: Aminute;
    font-size: 15px;
    letter-spacing: 3px;
    gap: 10px;

    div {
      cursor: pointer;
    }

    @media (max-width: 1050px) {
      font-size: 13px;
      letter-spacing: 2px;
    }

    @media (max-width: 750px) {
      display: none;
    }
  }

  .side__sections {
    display: flex;
    flex-direction: row;
    width: auto;
    flex-grow: 1;
    padding: 0px 75px;

    @media (max-width: 750px) {
      padding: 0px 20px;
    }
  }

  .side__section1 {
    justify-content: flex-start;
    cursor: pointer;
    // background-color: #165de5;
  }

  .side__section2 {
    justify-content: end;
    position: relative;
    z-index: 99;

    .icon__container {
      display: none;
      height: fit-content !important;
      margin: auto 0px;

      @media (max-width: 750px) {
        padding: 0px 20px;
        display: block;
      }
    }
  }
}

.app__body {
  display: flex;
  flex-grow: 1;
  overscroll-behavior: none;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  width: 100vw !important;
  height: 100vh;
}
.app__body::-webkit-scrollbar {
  display: none;
}

.home__page__container {
  /* background-color: blue; */
  width: 100%;
  color: #fff;
  overscroll-behavior: none;
  overflow-y: "auto";

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }

  .dropdown__section {
    display: none;
    position: fixed;
    flex-direction: column;
    // justify-content: space-around;
    width: max-content;
    text-align: center;
    align-items: center;
    z-index: 99;
    background-color: rgb(0, 0, 0);
    top: 60px;
    right: 0px;
    padding: 10px 40px;
    color: #fff;

    div {
      cursor: pointer;
      height: 35px;
    }

    @media (max-width: 750px) {
      display: flex;
    }
  }
}

.app__footer {
  height: 70px;
}

/* Components */

/* General */

.screen__sized__section {
  height: 100vh;
}

.video__intro {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
    object-fit: cover; /* Ensures the video covers the container */
    z-index: -1;
  }
}

.text__white {
  color: #fff;
}

/* Products section */

.products__page {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
}

.delayed__flow__in {
  transition-delay: 1s;
}

.products__page__left__column {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .image__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 25%;
    position: relative;
    height: 50%;

    .prod__img {
      animation: moveImageIn 1s ease-in-out forwards;
      // transition: transform 1s, opacity 0.8s ease-out;
      // position: absolute;
      // top: 100%;
      max-height: 100%;
    }

    @keyframes moveImageIn {
      0% {
        opacity: 0;
        transform: scale(0.4) translateY(100%);
      }
      100% {
        opacity: 1;
        transform: scale(1) translateY(0%);
      }
    }

    .pool__party {
      animation: moveImage1In 1s ease-in-out forwards;
      // transition: transform 1s, opacity 0.8s ease-out;
      // position: absolute;
      // top: 100%;
      max-height: 100%;
    }

    @keyframes moveImage1In {
      0% {
        opacity: 0;
        transform: scale(0.2) translateY(100%);
      }
      100% {
        opacity: 1;
        transform: scale(0.6) translateY(0%);
      }
    }

    // .img__flow__in {
    //   opacity: 1;
    //   transform: translateY(0) scale(1);
    // }

    .img__flow__out {
      animation: moveImageOut 1s ease-in-out forwards;
      // transition: transform 1s, opacity 0.8s ease-out;
      position: absolute;
      top: 0px;
      max-height: 100%;
    }

    @keyframes moveImageOut {
      0% {
        transform: translateY(0%);
      }
      100% {
        transform: translateY(-200%);
      }
    }

    .img2__flow__out {
      animation: moveImage2Out 1s ease-in-out forwards;
      // transition: transform 1s, opacity 0.8s ease-out;
      position: absolute;
      top: 0px;
      max-height: 100%;
    }

    @keyframes moveImage2Out {
      0% {
        transform: scale(0.6) translateY(0%);
      }
      100% {
        transform: scale(0.6) translateY(-200%);
      }
    }

    .delayed__flow__in {
      transition-delay: 0.5s;
    }

    .image__move__and__scale {
      animation: moveAndRotate 6s ease-in-out forwards;
      // opacity: 0;
      transition: opacity 0.5s;
      transition-delay: 6s;
    }

    @keyframes moveAndRotate {
      25% {
        transform: rotate(10deg) translateX(600%) translateY(75%) scale(1.5);
      }
      33.33% {
        transform: rotate(10deg) translateX(600%) translateY(75%) scale(1.5);
      }
      58.33% {
        transform: rotate(10deg) translateX(600%) translateY(75%) scale(1.5);
      }
      66.66% {
        transform: rotate(10deg) translateX(700%) translateY(125%) scale(1.7);
      }
      100% {
        transform: rotate(10deg) translateX(800%) translateY(850%) scale(25);
      }
    }
  }
}

.products__page__right__column {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .text__container {
    display: flex;
    flex-direction: column;
    height: 50%;
    font-size: 130px;
    width: 50%;
    text-align: right;
    margin-left: 30%;
    color: #fff;
  }

  .first__row {
    opacity: 0;
    transform: translateX(-40%) translateY(100%);
    transition: transform 1.5s, opacity 1s ease-out;
    color: #fff;
  }

  .second__row_container {
    position: relative;
    height: 100px;
  }

  .second__row {
    // opacity: 0;
    // transform: translateX(-100%);
    color: #165de5;
    position: absolute;
    right: 0px;
  }

  .flow__in {
    animation: moveTextIn 1.5s ease-in-out forwards;
  }

  @keyframes moveTextIn {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0) translateY(0);
    }
  }

  .flow__out {
    animation: moveTextOut 1.5s ease-in-out forwards;
  }

  @keyframes moveTextOut {
    0% {
      // opacity: 0;
      transform: translateX(0%);
    }
    100% {
      // opacity: 1;
      transform: translateX(200%);
    }
  }

  .delayed__flow__in {
    transition-delay: 0.5s;
  }
}

.bg {
  background-color: #165de5 !important;
}

.element {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  height: 100%;
  color: #000;
}

.fade-in {
  opacity: 1 !important;
}

.fade-out {
  opacity: 0 !important;
}

.ModalContainer {
  z-index: 999999 !important;
}
