.contact__us__container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    // background: url("../../assets/ContactUs/Group\ 127.png");
    background-color: #000;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .content__container {
        flex: 1;
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;

        .contact__img {
            width: 200px;
            height: 155px;
            margin: auto;

            @media (max-width: 750px) {
                width: 160px;
                height: 120px;
            }
        }

        .contact__us__header {
            font-size: 124px;
            color: #fff;
            margin: auto;
            font-family: Aminute;

            @media (max-width: 750px) {
                font-size: 60px;
            }
        }

        .contact__us__send__message {
            font-size: 27px;
            color: #fff;
            margin: auto;
            font-family: RedHat;
            cursor: pointer;

            .send__msg__img {
                height: 16px;
                width: 38px;
            }
        }
    }

    iframe {
        width: 100%;
    }
}
