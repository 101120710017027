.page__section {
    height: 100vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;
    font-family: Aminute;
    font-size: 16px;
    // background-color: #00aae83f;
    // opacity: 0;
    .text__content__container {
        position: absolute;
        width: 40%;
        z-index: 9;
        display: flex;
        flex-direction: column;
        margin-top: 85px;
        justify-content: center;
        top: 0%;
        height: calc(100% - 85px);
        left: 10%;
        text-align: left;
        color: #fff;
        z-index: 5;

        .text__content__sub__container {
            display: flex;
            flex-direction: column;
            height: max-content;
            text-align: left;
            gap: 45px;

            .text__content__title {
                font-size: 70px;
                margin: auto 0px;
                font-family: Aminute;

                @media (max-width: 1250px) {
                    font-size: 60px;
                }
                @media (max-width: 1050px) {
                    font-size: 50px;
                }

                @media (max-width: 750px) {
                    font-size: 25px;
                    line-height: 25px;
                    margin-bottom: 30px;
                }
            }

            .text__content {
                font-size: 26px;
                margin: auto;
                font-family: RedHat;

                @media (max-width: 1250px) {
                    font-size: 24px;
                }
                @media (max-width: 1050px) {
                    font-size: 20px;
                }
                @media (max-width: 750px) {
                    font-size: 14px;
                    line-height: 14px;
                }
            }

            @media (max-width: 750px) {
                width: 90%;
                top: 10%;
                left: 0%;
                margin: 50px auto;
                position: relative;
                gap: 10px;
            }
        }
        @media (max-width: 1250px) {
            width: 50%;
        }
        @media (max-width: 1050px) {
            width: 60%;
        }
        @media (max-width: 750px) {
            width: 90%;
            margin: 0px auto;
            height: 45%;
            top: 120px;
            left: 0%;
            // margin: auto;
            position: relative;
            justify-content: start;
            // text-align: center;
        }

        .animate__text__enter {
            // animation: wwd__content__animation 3s ease-in-out forwards;
        }

        .animate__text__leave {
            // animation: wwd__content__animation__leave 1.5s ease-in-out forwards;
        }

        .animate__text__leave__reverse {
            // animation: wwd__content__animation__leave__reverse 4s ease-in-out forwards;
        }

        @keyframes wwd__content__animation {
            0% {
                opacity: 0;
            }
            25% {
                opacity: 1;
            }
            100% {
                transform: translateY(0%);
            }
            // 100% {
            //     transform: translateY(-250%);
            // }
        }

        @keyframes wwd__content__animation__leave {
            0% {
                transform: translateY(0%);
            }
            100% {
                transform: translateY(-250%);
            }
        }

        @keyframes wwd__content__animation__leave__reverse {
            0% {
                transform: translateY(-250%);
            }
            75% {
                transform: translateY(-250%);
            }
            100% {
                transform: translateY(0%);
            }
        }
    }

    .wwd__bg__text {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: row;
        width: 66%;
        margin-left: auto;
        position: relative;
        font-size: 650px;
        line-height: 475px;
        font-weight: 950;
        color: #fff;

        .lighters__container {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            z-index: 3;

            .lighters__sub__container {
                width: 100%;
                height: 100%;
                position: relative;

                img {
                    z-index: 99;

                    @media (max-width: 1250px) {
                        scale: 0.9;
                    }
                    @media (max-width: 1050px) {
                        scale: 0.8;
                    }
                    @media (max-width: 750px) {
                        scale: 0.4;
                    }
                }

                .lighter__1 {
                    position: absolute;
                    width: 96px;
                    height: 387px;
                    transform-origin: center !important;
                    top: -5%;
                    left: 50%;
                    transform: rotate(15deg);

                    @media (max-width: 1250px) {
                        left: 40%;
                    }
                    @media (max-width: 1050px) {
                        left: 35%;
                    }
                    @media (max-width: 750px) {
                        left: 25%;
                        top: -20%;
                    }
                }

                .lighter__2 {
                    position: absolute;
                    width: 84px;
                    height: 503px;
                    transform-origin: center !important;
                    bottom: 0%;
                    left: 75%;
                    transform: rotate(-20deg);

                    @media (max-width: 1250px) {
                        left: 60%;
                    }
                    @media (max-width: 1050px) {
                        left: 65%;
                    }
                    @media (max-width: 750px) {
                        left: 65%;
                        top: -15%;
                    }
                }

                .lighter__3 {
                    position: absolute;
                    width: 91px;
                    height: 281px;
                    transform-origin: center !important;
                    top: 20%;
                    right: 10%;
                    transform: rotate(-45deg);

                    @media (max-width: 1250px) {
                        right: 10%;
                    }
                    @media (max-width: 1050px) {
                        right: 10%;
                    }
                    @media (max-width: 750px) {
                        left: 63%;
                        top: -14%;
                    }
                }

                @media (max-width: 750px) {
                    // margin-top: 55%;
                    height: 100%;
                    // margin: auto;
                    position: relative;
                }
            }
            @media (max-width: 750px) {
                margin-top: 55%;
                height: 55%;
            }
        }

        .lgihters__animate__enter {
            // animation: lighters__animation__enter 3s ease-in-out forwards;
        }

        .lgihters__animate__leave {
            // animation: lighters__animation__leave 1.5s ease-in-out forwards;
        }

        .lgihters__animate__leave__reverse {
            // animation: lighters__animation__leave__reverse 4s ease-in-out forwards;
        }

        @keyframes lighters__animation__enter {
            0% {
                opacity: 0;
                transform: translateY(350px) translateX(-300px);
            }
            20% {
                opacity: 0;
                transform: translateY(350px) translateX(-300px);
            }
            40% {
                opacity: 1;
                transform: translateY(0%) translateX(0%);
            }
            100% {
                opacity: 1;
                transform: translateY(0%);
            }
        }

        @keyframes lighters__animation__leave {
            0% {
                transform: translateY(0%);
            }
            100% {
                transform: translateY(-250%);
            }
        }

        @keyframes lighters__animation__leave__reverse {
            0% {
                transform: translateY(-250%);
            }
            75% {
                transform: translateY(-250%);
            }
            100% {
                transform: translateY(0%);
            }
        }
        // 100% {
        //     opacity: 1;
        //     transform: translateY(-250%);
        // }

        .bg__text__container {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;

            .content__column {
                width: 51%;
            }

            .left__column {
                position: absolute;
                text-align: right;
                opacity: 0.2;
                left: 0px;

                @media (max-width: 750px) {
                }
            }

            .right__column {
                top: 10%;
                position: absolute;
                text-align: left;
                opacity: 0.2;
                right: 0px;

                @media (max-width: 750px) {
                    // text-align: right;
                }
            }

            .shifted__letter__left {
                margin-left: 75px;

                @media (max-width: 1250px) {
                    margin-left: 50px;
                }
                @media (max-width: 1050px) {
                    margin-left: 50px;
                }
                @media (max-width: 750px) {
                    margin-left: 50px;
                }
            }
        }

        .bg__text__enter {
            // animation: slide__bg__text__enter 1.5s ease-in-out forwards;
        }

        .bg__text__enter__leave {
            // animation: slide__bg__text__leave 1.5s ease-in-out forwards;
        }

        .bg__text__enter__leave__reverse {
            // animation: slide__bg__text__leave__reverse 4s ease-in-out forwards;
        }

        @keyframes slide__bg__text__enter {
            0% {
                transform: translateY(75%);
            }
            100% {
                transform: translateY(-6%);
            }
        }

        @keyframes slide__bg__text__leave {
            0% {
                transform: translateY(0%);
            }
            100% {
                transform: translateY(-250%);
            }
        }

        @keyframes slide__bg__text__leave__reverse {
            0% {
                transform: translateY(-250%);
            }
            75% {
                transform: translateY(-250%);
            }
            100% {
                transform: translateY(0%);
            }
        }

        @media (max-width: 1250px) {
            font-size: 550px;
            line-height: 400px;
        }
        @media (max-width: 1050px) {
            font-size: 450px;
            line-height: 330px;
        }
        @media (max-width: 750px) {
            position: absolute;
            width: 100%;
            font-size: 300px;
            line-height: 250px;
            top: 17%;
            margin-left: 0px !important;
        }
    }

    .lighter__4 {
        position: absolute;
        transform-origin: center;

        bottom: 20%;
        right: 22%;
        height: 220px;
        width: 825px;
        transform: rotate(-55deg);
        // transform-origin: top !important;

        .lighter__4__images__container {
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: 1;
            position: relative;
            max-height: 100%;
            // overflow-y: hidden;

            .lighter__4__image__sky {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100% !important;
                height: 100%;
                // height: 40%;
                object-fit: cover;
                // transform-origin: fill !important;
            }

            // .lighter__4__images__bottom {
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     right: 0;
            //     bottom: 0;
            //     width: 100% !important;
            //     max-height: 100%;
            //     object-fit: cover;
            //     // height: 100%;
            //     // height: 60%;
            // }
        }

        @media (max-width: 1250px) {
            bottom: 25%;
            right: 10%;
            height: 175px;
            width: 748px;
        }

        @media (max-width: 1050px) {
            bottom: 35%;
            right: 10%;
            height: 150px;
            width: 561px;
        }

        @media (max-width: 750px) {
            bottom: 16%;
            right: 20%;
            height: 65px;
            width: 245px;
        }
    }

    .animate__enter {
        // animation: lighters__animation4 1600ms ease-in-out forwards;

        @media (max-width: 1250px) {
            // animation: lighters__animation4__1 1600ms ease-in-out forwards;
        }

        @media (max-width: 1050px) {
            // animation: lighters__animation4__2 1600ms ease-in-out forwards;
        }

        @media (max-width: 750px) {
            // animation: lighters__animation4__3 1600ms ease-in-out forwards;
        }
    }

    @keyframes lighters__animation4 {
        0% {
            bottom: 20%;
            right: 5%;
            height: 220px;
            width: 825px;
            opacity: 0;
            transform: translateY(0px) translateX(-300px) rotate(-55deg);
        }
        20% {
            bottom: 20%;
            right: 5%;
            height: 220px;
            width: 825px;
            opacity: 0;
            transform: translateY(0px) translateX(-300px) rotate(-55deg);
        }
        100% {
            bottom: 20%;
            right: 5%;
            height: 220px;
            width: 825px;
            opacity: 1;
            transform: translateY(0%) translateX(0%) rotate(-55deg);
        }
        // 100% {
        //     bottom: 15%;
        //     right: 25%;
        //     height: 250px;
        //     width: 935px;
        //     opacity: 1;
        //     transform: translateY(0%) rotate(-55deg);
        // }
    }

    @keyframes lighters__animation4__1 {
        0% {
            bottom: 25%;
            right: 10%;
            height: 175px;
            width: 748px;
            opacity: 0;
            transform: translateY(0px) translateX(-300px) rotate(-55deg);
        }
        20% {
            opacity: 0;
            transform: translateY(0px) translateX(-300px) rotate(-55deg);
        }
        100% {
            bottom: 25%;
            right: 10%;
            height: 175px;
            width: 748px;
            opacity: 1;
            transform: translateY(0%) translateX(0%) rotate(-55deg);
        }
        // 100% {
        //     bottom: 25%;
        //     right: 10%;
        //     height: 175px;
        //     width: 748px;
        //     opacity: 1;
        //     transform: translateY(0%) rotate(-55deg);
        // }
    }

    @keyframes lighters__animation4__2 {
        0% {
            bottom: 35%;
            right: 10%;
            height: 150px;
            width: 561px;
            opacity: 0;
            transform: translateY(0px) translateX(-300px) rotate(-55deg);
        }
        20% {
            opacity: 0;
            transform: translateY(0px) translateX(-300px) rotate(-55deg);
        }
        100% {
            bottom: 35%;
            right: 10%;
            height: 150px;
            width: 561px;
            opacity: 1;
            transform: translateY(0%) translateX(0%) rotate(-55deg);
        }
        // 100% {
        //     bottom: 35%;
        //     right: 10%;
        //     height: 150px;
        //     width: 561px;
        //     opacity: 1;
        //     transform: translateY(0%) rotate(-55deg);
        // }
    }

    @keyframes lighters__animation4__3 {
        0% {
            bottom: 20%;
            right: 10%;
            height: 65px;
            width: 245px;
            opacity: 0;
            transform: translateY(0px) translateX(-300px) rotate(-55deg);
        }
        20% {
            opacity: 0;
            transform: translateY(0px) translateX(-300px) rotate(-55deg);
        }
        100% {
            bottom: 20%;
            right: 10%;
            height: 85px;
            width: 320px;
            opacity: 1;
            transform: translateY(0%) translateX(0%) rotate(-55deg);
        }
    }

    .animate__leave {
        bottom: 20%;
        right: 10%;
        height: 220px;
        width: 825px;
        transform: rotate(-55deg);
        // animation: lighters__animation4__leave 4s ease-in-out forwards;

        @media (max-width: 1250px) {
            bottom: 25%;
            right: 10%;
            height: 175px;
            width: 748px;
            // animation: lighters__animation4__leave__1 4s ease-in-out forwards;
        }

        @media (max-width: 1050px) {
            bottom: 35%;
            right: 10%;
            height: 150px;
            width: 561px;
            // animation: lighters__animation4__leave__2 4s ease-in-out forwards;
        }

        @media (max-width: 750px) {
            bottom: 20%;
            right: 10%;
            height: 85px;
            width: 320px;
            // animation: lighters__animation4__leave__3 4s ease-in-out forwards;
        }
    }

    @keyframes lighters__animation4__leave {
        0% {
            bottom: 20%;
            right: 5%;
            height: 220px;
            width: 825px;
            transform: rotate(-55deg);
        }
        20% {
            right: -10%;
            bottom: 25%;
            height: 30vh;
            width: 80vw;
            opacity: 1;
            transform: translateY(0%) rotate(-40deg);
        }
        40% {
            opacity: 1;
            bottom: 30%;
            width: 100vw;
            transform: rotate(-25deg);
        }
        50% {
            opacity: 1;
            width: 100vw;
            transform: rotate(-10deg);
        }
        60% {
            bottom: 0;
            opacity: 1;
            right: 0;
            transform: translateY(0%) rotate(0deg);
            height: 100vh;
            width: 100vw;
        }
        100% {
            bottom: 0;
            opacity: 1;
            right: 0;
            transform: translateY(0%) rotate(0deg);
            height: 100vh;
            width: 100vw;
        }
    }

    @keyframes lighters__animation4__leave__1 {
        0% {
            bottom: 25%;
            right: 10%;
            height: 175px;
            width: 748px;
            transform: rotate(-55deg);
        }
        20% {
            right: -10%;
            bottom: 25%;
            height: 30vh;
            width: 80vw;
            opacity: 1;
            transform: translateY(0%) rotate(-40deg);
        }
        40% {
            opacity: 1;
            bottom: 30%;
            width: 100vw;
            transform: rotate(-25deg);
        }
        50% {
            opacity: 1;
            width: 100vw;
            transform: rotate(-10deg);
        }
        60% {
            bottom: 0;
            opacity: 1;
            right: 0;
            transform: translateY(0%) rotate(0deg);
            height: 100vh;
            width: 100vw;
        }
        100% {
            bottom: 0;
            opacity: 1;
            right: 0;
            transform: translateY(0%) rotate(0deg);
            height: 100vh;
            width: 100vw;
        }
    }

    @keyframes lighters__animation4__leave__2 {
        0% {
            bottom: 35%;
            right: 10%;
            height: 150px;
            width: 561px;
            transform: rotate(-55deg);
        }
        20% {
            right: -10%;
            bottom: 25%;
            height: 30vh;
            width: 80vw;
            opacity: 1;
            transform: translateY(0%) rotate(-40deg);
        }
        40% {
            opacity: 1;
            bottom: 30%;
            width: 100vw;
            transform: rotate(-25deg);
        }
        50% {
            opacity: 1;
            width: 100vw;
            transform: rotate(-10deg);
        }
        60% {
            bottom: 0;
            opacity: 1;
            right: 0;
            transform: translateY(0%) rotate(0deg);
            height: 100vh;
            width: 100vw;
        }
        100% {
            bottom: 0;
            opacity: 1;
            right: 0;
            transform: translateY(0%) rotate(0deg);
            height: 100vh;
            width: 100vw;
        }
    }

    @keyframes lighters__animation4__leave__3 {
        0% {
            bottom: 20%;
            right: 10%;
            height: 85px;
            width: 320px;
            transform: rotate(-55deg);
        }
        20% {
            right: -10%;
            bottom: 25%;
            height: 30vh;
            width: 80vw;
            opacity: 1;
            transform: translateY(0%) rotate(-40deg);
        }
        40% {
            opacity: 1;
            bottom: 30%;
            width: 100vw;
            transform: rotate(-25deg);
        }
        50% {
            opacity: 1;
            width: 100vw;
            transform: rotate(-10deg);
        }
        60% {
            bottom: 0;
            opacity: 1;
            right: 0;
            transform: translateY(0%) rotate(0deg);
            height: 100vh;
            width: 100vw;
        }
        100% {
            bottom: 0;
            opacity: 1;
            right: 0;
            transform: translateY(0%) rotate(0deg);
            height: 100vh;
            width: 100vw;
        }
    }

    .animate__leave__reverse {
        bottom: 20%;
        right: 10%;
        height: 220px;
        width: 825px;
        transform: rotate(-55deg);
        // animation: lighters__animation4_reverseleave 4s ease-in-out reverse;

        @media (max-width: 1250px) {
            bottom: 25%;
            right: 10%;
            height: 175px;
            width: 748px;
            transform: rotate(-55deg);
            // animation: lighters__animation4_reverseleave__1 4s ease-in-out reverse;
        }

        @media (max-width: 1050px) {
            bottom: 35%;
            right: 10%;
            height: 150px;
            width: 561px;
            transform: rotate(-55deg);
            // animation: lighters__animation4_reverseleave__2 4s ease-in-out reverse;
        }

        @media (max-width: 750px) {
            bottom: 20%;
            right: 10%;
            height: 85px;
            width: 320px;
            transform: rotate(-55deg);
            // animation: lighters__animation4_reverseleave__3 4s ease-in-out reverse;
        }
    }

    @keyframes lighters__animation4_reverseleave {
        0% {
            bottom: 20%;
            right: 5%;
            height: 220px;
            width: 825px;
            transform: rotate(-55deg);
        }
        20% {
            right: -10%;
            bottom: 25%;
            height: 30vh;
            width: 80vw;
            opacity: 1;
            transform: translateY(0%) rotate(-40deg);
        }
        40% {
            opacity: 1;
            bottom: 30%;
            width: 100vw;
            transform: rotate(-25deg);
        }
        50% {
            opacity: 1;
            width: 100vw;
            transform: rotate(-10deg);
        }
        60% {
            bottom: 0;
            opacity: 1;
            right: 0;
            transform: translateY(0%) rotate(0deg);
            height: 100vh;
            width: 100vw;
        }
        100% {
            bottom: 0;
            opacity: 1;
            right: 0;
            transform: translateY(0%) rotate(0deg);
            height: 100vh;
            width: 100vw;
        }
    }

    @keyframes lighters__animation4_reverseleave__1 {
        0% {
            bottom: 25%;
            right: 10%;
            height: 175px;
            width: 748px;
            transform: rotate(-55deg);
        }
        20% {
            right: -10%;
            bottom: 25%;
            height: 30vh;
            width: 80vw;
            opacity: 1;
            transform: translateY(0%) rotate(-40deg);
        }
        40% {
            opacity: 1;
            bottom: 30%;
            width: 100vw;
            transform: rotate(-25deg);
        }
        50% {
            opacity: 1;
            width: 100vw;
            transform: rotate(-10deg);
        }
        60% {
            bottom: 0;
            opacity: 1;
            right: 0;
            transform: translateY(0%) rotate(0deg);
            height: 100vh;
            width: 100vw;
        }
        100% {
            bottom: 0;
            opacity: 1;
            right: 0;
            transform: translateY(0%) rotate(0deg);
            height: 100vh;
            width: 100vw;
        }
    }

    @keyframes lighters__animation4_reverseleave__2 {
        0% {
            bottom: 35%;
            right: 10%;
            height: 150px;
            width: 561px;
            transform: rotate(-55deg);
        }
        20% {
            right: -10%;
            bottom: 25%;
            height: 30vh;
            width: 80vw;
            opacity: 1;
            transform: translateY(0%) rotate(-40deg);
        }
        40% {
            opacity: 1;
            bottom: 30%;
            width: 100vw;
            transform: rotate(-25deg);
        }
        50% {
            opacity: 1;
            width: 100vw;
            transform: rotate(-10deg);
        }
        60% {
            bottom: 0;
            opacity: 1;
            right: 0;
            transform: translateY(0%) rotate(0deg);
            height: 100vh;
            width: 100vw;
        }
        100% {
            bottom: 0;
            opacity: 1;
            right: 0;
            transform: translateY(0%) rotate(0deg);
            height: 100vh;
            width: 100vw;
        }
    }

    @keyframes lighters__animation4_reverseleave__3 {
        0% {
            bottom: 20%;
            right: 10%;
            height: 85px;
            width: 320px;
            transform: rotate(-55deg);
        }
        20% {
            right: -10%;
            bottom: 25%;
            height: 30vh;
            width: 80vw;
            opacity: 1;
            transform: translateY(0%) rotate(-40deg);
        }
        40% {
            opacity: 1;
            bottom: 30%;
            width: 100vw;
            transform: rotate(-25deg);
        }
        50% {
            opacity: 1;
            width: 100vw;
            transform: rotate(-10deg);
        }
        60% {
            bottom: 0;
            opacity: 1;
            right: 0;
            transform: translateY(0%) rotate(0deg);
            height: 100vh;
            width: 100vw;
        }
        100% {
            bottom: 0;
            opacity: 1;
            right: 0;
            transform: translateY(0%) rotate(0deg);
            height: 100vh;
            width: 100vw;
        }
    }

    @media (max-width: 750px) {
        flex-direction: column;
    }
}

.footer {
    max-width: 100vw;

    // min-height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 35px 25px;
    color: #fff;
    font-family: "RedHat";
    font-size: 14px;
    background-color: black;

    @media (max-width: 750px) {
        flex-direction: column;
    }
    .left {
        display: flex;
        flex-direction: row;
        // justify-content: center;
        height: 100%;
        gap: 25px;
        width: 48%;

        .links__container {
            display: flex;
            flex-direction: row;
            gap: 10px;
            height: 100% !important;
            margin: auto 0px;

            @media (max-width: 750px) {
                display: none;
            }

            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        @media (max-width: 750px) {
            width: 100%;
            justify-content: center;
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: max-content;
        width: 48%;

        @media (max-width: 750px) {
            flex-direction: row;
            margin-top: 25px;
            width: 100%;
        }
    }
}
