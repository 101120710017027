*{
/* scrollbar-gutter: stable both-edges !important; */
overscroll-behavior: none;
-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
/* scrollbar-gutter: stable both-edges !important; */
overscroll-behavior: none;
-ms-overflow-style: none; /* IE and Edge */
scrollbar-width: none; /* Firefox */
background-color: black;
}

body,
* {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

body::-webkit-scrollbar,
*::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

body::-webkit-scrollbar {
  display: none;
}

#root{
  width: 100vw !important;
  background-color: aqua;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: 'Aminute';
  src: local('Aminute'), url('./assets/Aminute.ttf') format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'RedHat';
  src: local('RedHat'), url('./assets/RedHatDisplayMedium.ttf') format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
