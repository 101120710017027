.video {
    position: fixed;
    width: 100vw;
    height: 100vdh;
    z-index: 9999;
}

.main__page {
    width: 100vw !important;
    height: 100vh;
    background-color: #000;

    .main__page__spacer {
        width: 100vw !important;
        height: 1800vh;
        background-color: #000;
    }

    .main__page__spacer__2 {
        width: 100vw !important;
        height: 500vh;
        background-color: #000;
    }
    .footer {
        max-width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 35px 25px;
        color: #fff;
        font-family: "RedHat";
        font-size: 14px;
        background-color: black;

        @media (max-width: 750px) {
            flex-direction: column;
        }
        .left {
            display: flex;
            flex-direction: row;
            height: 100%;
            gap: 25px;
            width: 48%;

            .links__container {
                display: flex;
                flex-direction: row;
                gap: 10px;
                height: 100% !important;
                margin: auto 0px;
                font-family: Aminute;

                @media (max-width: 750px) {
                    display: none;
                }

                div {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    cursor: pointer;
                }
            }

            @media (max-width: 750px) {
                width: 100%;
                justify-content: center;
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: right;
            width: max-content;
            width: 48%;

            @media (max-width: 750px) {
                flex-direction: row;
                margin-top: 25px;
                width: 100%;
                text-align: center;
            }
        }
    }
}

.logo_img {
    max-height: 50px;
    aspect-ratio: 20/9 !important;
}

.scroll__down {
    position: fixed;
    bottom: 50px;
    left: 50vw;
    z-index: 999999999;
    transform: translate(-50%, 0%) rotate(90deg);
    -webkit-transform: translate(-50%, 0%) rotate(90deg);
    color: #fff;
    font-size: 50px;
    animation: bounce 2s infinite ease-in-out;

    @media (max-width: 750px) {
        font-size: 30px;
        bottom: 30px;
    }
}

@keyframes bounce {
    0%,
    100% {
        transform: translateY(0) translate(-50%, 0%) rotate(90deg);
        -webkit-transform: translateY(0) translate(-50%, 0%) rotate(90deg);
    }
    50% {
        transform: translateY(-25px) translate(-50%, 0%) rotate(90deg);
        -webkit-transform: translateY(-25px) translate(-50%, 0%) rotate(90deg);
    }
}
